<template>
  <DataGrid
    v-model="selectedComplaints"
    :card="card"
    :minimumCardWidth="580"
    :display.sync="display"
    :search="searchField"
    :items="complaints"
    :pagination="pagination"
    :isLoading="isLoadingComplaints || isLoadingPrioritiesAndCategories"
    :expandedComplaintIds="expandedComplaintIds"
    @click="showDetails"
    @update:search="searchHandler"
    @update:pagination="getComplaints"
    @update:item="updateComplaint"
    @expand="onExpandDetails"
  >
    <template #header>
      <div class="header">
        <OrderPicker :options="orderByOptions" v-model="orderBy" />
        <FiltersPicker
          :categories="filterOptions"
          v-model="filters"
          :displaySearchField="isMobile"
          :searchTextValue="searchField"
          @onSearchTextValueChange="searchHandler"
        />
        <div class="customer-field-filters">
          <v-text-field
            v-if="initialSelectedCustomer"
            clearable
            hide-details
            readonly
            style="margin: 0; padding: 0"
            prepend-icon="stem-person"
            :label="$t('complaints.caller')"
            :value="initialSelectedCustomer"
            @click:clear="onClearInitialCustomer"
          />
          <CustomerField v-else v-model="selectedCustomer" returnObject />
        </div>
      </div>
      <ConfirmModal
        :value="isConfirmExportModalShown"
        :width="420"
        :loading="isExportLoading"
        :confirmText="
          $t('complaints.exportAllToExcelMsg', {
            count: pagination.itemsLength,
          })
        "
        @confirm="exportAllToExcel"
        @cancel="cancelExportAllToExcel"
        v-can:CPL_WRITE
      />
    </template>
    <template #toolbar>
      <SelectionPicker
        :options="complaintStates"
        :disabled="isLoadingComplaints"
        v-model="selectedStates"
      ></SelectionPicker>
    </template>
    <template #toolbar-actions>
      <v-btn
        large
        icon
        text
        class="toolbar-buttons"
        v-can:CPL_WRITE
        :disabled="!selectedCustomer"
        @click="onAddNewComplaint"
      >
        <IconBox :tooltip="$t('complaints.add')" color="iconButton">
          stem-complaintPlus
        </IconBox>
      </v-btn>
      <v-btn
        large
        icon
        text
        @click="selectAll"
        class="toolbar-buttons"
        v-if="!isMobile"
      >
        <IconBox
          v-if="selectedAll"
          :tooltip="$t('common.unselectAll')"
          color="iconButton"
        >
          stem-selectedAll
        </IconBox>
        <IconBox v-else :tooltip="$t('common.selectAll')" color="iconButton">
          stem-selectAll
        </IconBox>
      </v-btn>
      <v-menu
        v-if="!isMobile"
        offset-y
        :close-on-content-click="false"
        v-model="headerActionsMenuOpen"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            slot="activator"
            icon
            text
            v-bind="attrs"
            v-on="on"
            class="toolbar-buttons"
          >
            <IconBox color="iconButton">stem-headerActions</IconBox>
          </v-btn>
        </template>
        <v-list>
          <v-list-group
            v-if="
              isAnySelected &&
              allowStatusChange &&
              achievableStatusTransitions.length > 0
            "
            :value="isAnySelected"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <IconBox color="iconButton"> stem-flags </IconBox>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span>
                    {{ $t("complaints.changeState") }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="state in achievableStatusTransitions"
              :key="state.id"
              @click="changeComplaintsStates(state.id)"
              :disabled="!isAnySelected"
              class="group-list"
            >
              <v-list-item-content xs-align-right>
                <v-list-item-title class="group-item-title">
                  {{ state.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-if="isAnySelected && display === displayListTypes.INLINE"
            @click="showHideDescription"
          >
            <v-list-item-avatar>
              <IconBox color="iconButton"> stem-showDescription </IconBox>
            </v-list-item-avatar>
            <v-list-item-content xs-align-right>
              <v-list-item-title>
                {{
                  selectedComplaintsExpanded
                    ? $t("common.hideDescription")
                    : $t("common.showDescription")
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="isAnySelected"
            @click="exportToCsv"
            v-can:CPL_WRITE
          >
            <v-list-item-avatar>
              <IconBox color="iconButton"> stem-export </IconBox>
            </v-list-item-avatar>
            <v-list-item-content xs-align-right>
              <v-list-item-title>{{ $t("common.export") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="
              () => {
                headerActionsMenuOpen = false;
                isConfirmExportModalShown = true;
              }
            "
          >
            <v-list-item-avatar>
              <IconBox color="iconButton">stem-exportAll</IconBox>
            </v-list-item-avatar>
            <v-list-item-content xs-align-right>
              <v-list-item-title>
                {{ $t("common.exportAll") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template #footer>
      <div class="mobile-button" @click="selectAll">
        <IconBox v-if="selectedAll" color="iconButton">
          stem-selectedAll
        </IconBox>
        <IconBox v-else color="iconButton"> stem-selectAll </IconBox>
        <span class="label">
          {{ selectedAll ? $t("common.unselectAll") : $t("common.selectAll") }}
        </span>
      </div>

      <div
        v-if="selectedCustomer"
        class="mobile-button"
        @click="onAddNewComplaint"
      >
        <IconBox color="iconButton">stem-complaintPlus</IconBox>
        <span class="label">{{ $t("complaints.addComplaint") }}</span>
      </div>

      <v-menu
        offset-y
        top
        :close-on-content-click="false"
        v-model="headerActionsMenuOpen"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="mobile-button" v-on="on" v-bind="attrs">
            <IconBox color="iconButton">stem-headerActions</IconBox>
            <span class="label">{{ $t("common.actions") }}</span>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-if="isAnySelected && display === displayListTypes.INLINE"
            @click="showHideDescription"
          >
            <v-list-item-avatar>
              <IconBox color="iconButton"> stem-showDescription </IconBox>
            </v-list-item-avatar>
            <v-list-item-content xs-align-right>
              <v-list-item-title>
                {{
                  selectedComplaintsExpanded
                    ? $t("common.hideDescription")
                    : $t("common.showDescription")
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="isAnySelected"
            @click="exportToCsv"
            v-can:CPL_WRITE
          >
            <v-list-item-avatar>
              <IconBox color="iconButton"> stem-export </IconBox>
            </v-list-item-avatar>
            <v-list-item-content xs-align-right>
              <v-list-item-title>{{ $t("common.export") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="
              () => {
                headerActionsMenuOpen = false;
                isConfirmExportModalShown = true;
              }
            "
          >
            <v-list-item-avatar>
              <IconBox color="iconButton">stem-exportAll</IconBox>
            </v-list-item-avatar>
            <v-list-item-content xs-align-right>
              <v-list-item-title>
                {{ $t("common.exportAll") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        offset-y
        top
        :close-on-content-click="false"
        v-model="changeStateMenuOpen"
        v-if="
          isAnySelected &&
          allowStatusChange &&
          achievableStatusTransitions.length > 0
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="mobile-button" v-on="on" v-bind="attrs">
            <IconBox color="iconButton"> stem-flags </IconBox>
            <span class="label">{{ $t("complaints.changeState") }}</span>
          </div>
        </template>

        <v-list>
          <v-list-item
            v-for="state in achievableStatusTransitions"
            :key="state.id"
            @click="changeComplaintsStates(state.id)"
          >
            <v-list-item-content xs-align-right>
              <v-list-item-title>
                {{ state.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </DataGrid>
</template>

<script>
import { mapProps } from "@/store";
import { mapGetters } from "vuex";

import DisplayListTypes from "@/models/displayListTypes.js";
import { CPL_READ } from "@/models/restrictions.js";

import ComplaintsService from "@/services/ComplaintsService";
import UsersService from "@/services/UsersService";

import {
  generateCsv,
  downloadFile,
  downloadCsvFile,
} from "../utils/FileManager.js";
import { EmitError, EmitSuccess } from "@/eventBus.js";
import { getComplaintStateColor } from "@/utils/ComplaintStatesHelper";

import DataGrid from "../components/Shared/DataGrid";
import FiltersPicker from "../components/Shared/FiltersPicker";
import CustomerField from "@/components/Fields/CustomerField";
import OrderPicker from "../components/Shared/OrderPicker";
import SelectionPicker from "../components/Shared/SelectionPicker";
import ComplaintCard from "@/components/Complaints/ComplaintCard";
import ConfirmModal from "@/components/Shared/ConfirmModal";

export default {
  name: "CustomerComplaintsList",
  components: {
    DataGrid,
    FiltersPicker,
    OrderPicker,
    CustomerField,
    SelectionPicker,
    ConfirmModal,
  },
  data() {
    return {
      selectedComplaints: [],
      searchField: "",
      expandedComplaintIds: [],
      filters: {},
      pagination: {
        page: 1,
        itemsPerPage: 12,
        totalItems: 0,
      },
      isConfirmExportModalShown: false,
      displayListTypes: DisplayListTypes,
      isLoadingComplaints: false,
      isLoadingPrioritiesAndCategories: false,
      card: ComplaintCard,
      complaints: [],
      orderByOptions: [
        "INSERTDATE",
        "LASTMODIFIEDDATE",
        "COMMENTINSERTDATE",
        "PRIORITY",
        "STATUS",
        "CATEGORY",
        "CALLER",
        "OWNER",
        "JIRAIDNUMBER",
      ],
      filterOptions: [],
      fieldsToExport: {
        cplCid: this.$t("complaints.CID"),
        priorityName: this.$t("complaints.priority"),
        title: this.$t("complaints.title"),
        category: this.$t("complaints.category"),
        cplStateName: this.$t("complaints.status"),
        complainerFirstname: this.$t("common.firstName"),
        complainerSurname: this.$t("common.surName"),
        insertUser: this.$t("complaints.owner"),
        insertDate: this.$t("complaints.insertDate"),
        realizationDate: this.$t("complaints.realisationDate"),
      },
      complaintStates: [],
      priorities: [],
      isExportLoading: false,
      headerActionsMenuOpen: false,
      changeStateMenuOpen: false,
      selectedCustomer: null,
      initialSelectedCustomer: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.setInitialCustomer(to.params.initialSelectedCustomer));
  },
  created() {
    this.isLoadingPrioritiesAndCategories = true;
    Promise.all([
      ComplaintsService.GetPriorities(),
      ComplaintsService.getCategoriesHierarchy(CPL_READ),
    ])
      .then(([prioritiesPayload, categoryHierarchyPayload]) => {
        const mappedCategories = categoryHierarchyPayload.map((category) => {
          return {
            id: category.categoryId,
            name: category.categoryName,
            parentId: null,
            children: category.subcategories.map((subCategory) => {
              return {
                id: subCategory.subCategoryId,
                name: subCategory.subCategoryName,
              };
            }),
          };
        });

        this._setFilters(prioritiesPayload, mappedCategories);
      })
      .finally(() => (this.isLoadingPrioritiesAndCategories = false));
  },
  watch: {
    filtersDto: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.pagination.page = 1;
        this.getComplaints();
      },
      deep: true,
    },
    mobileSearchTextValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.searchHandler(newValue);
        }
      },
    },
    selectedCustomer(newValue) {
      if (newValue) {
        this.getComplaints();
      } else {
        this.complaints = [];
        this.complaintStates = [];
        this.expandedComplaintIds = [];
        this.selectedComplaints = [];
        this.pagination = {
          page: 1,
          itemsPerPage: 12,
          totalItems: 0,
        };
      }
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    ...mapProps(["mobileSearchTextValue"], { options: "shared" }),
    ...mapProps(["orderBy", "display", "selectedStates"], {
      options: "customerComplaintOptions",
      action: "saveCustomerComplaintOption",
    }),
    filtersDto() {
      return {
        search: this.searchField,
        states: this.selectedStates.map((t) => t.id),
        orderBy: this.orderBy.name,
        orderDesc: this.orderBy.desc,
        ...this.formatFilters(),
      };
    },
    selectedAll() {
      return (
        this.complaints.every((x) =>
          this.selectedComplaints.some((y) => x.id == y.id)
        ) && this.selectedComplaints.length !== 0
      );
    },
    isAnySelected() {
      return this.selectedComplaints.length > 0;
    },
    selectedComplaintsExpanded() {
      return (
        this.selectedComplaints.length > 0 &&
        this.selectedComplaints.every((x) =>
          this.expandedComplaintIds.some((y) => x.id === y)
        )
      );
    },
    achievableStatusTransitions() {
      const selectedComplaintsNextStates = this.complaintStates
        .filter((state) => {
          return this.selectedComplaints
            .map((selectedComplaint) => selectedComplaint.cplStateId)
            .includes(state.id);
        })
        .map((item) => item.nextStates);

      return this.complaintStates.filter((state) => {
        return selectedComplaintsNextStates.every((nextState) =>
          nextState.includes(state.id)
        );
      });
    },
    allowStatusChange() {
      return this.selectedComplaints.every(
        (selectedComplaint) => !selectedComplaint.isMreStatus
      );
    },
  },
  methods: {
    _handleStatusChangeValidationErrors(errorCode, errorDetails) {
      switch (errorCode) {
        case "STATE_CHANGE_FORBIDDEN":
          EmitError(this.$t("complaints.statusChangeFailure"));
          break;
        case "REQUIRED_FIELDS_IN_DESTINATION_STATE":
          EmitError(this.$t("complaints.additionalInfo"));
          break;
        case "CPL_NOT_FOUND":
          EmitError(this.$t("complaints.notFound"));
          break;
      }
      const complaints = this.complaints;

      Object.keys(errorDetails).forEach((cplId) => {
        const complaintCard = complaints.find((c) => c.id === cplId);
        if (complaintCard) {
          complaintCard.error = errorDetails[cplId].errorDescription;
        }
      });
    },
    changeComplaintsStates(newState) {
      this.changeStateMenuOpen = false;
      ComplaintsService.changeComplaintStatuses(
        this.selectedComplaints.map((c) => c.id),
        newState
      ).then((r) => {
        if (r.isError) {
          this._handleStatusChangeValidationErrors(
            r.error.errorCode,
            r.error.errors
          );
        } else {
          EmitSuccess(this.$t("complaints.statusChangedSuccessfully"));
          this.selectedComplaints = [];
          this.getComplaints();
        }
      });
    },
    exportToCsv() {
      this.headerActionsMenuOpen = false;
      if (this.selectedComplaints.length !== 0) {
        const headers = Object.values(this.fieldsToExport);
        const items = this._formatDataToExport(this.selectedComplaints);
        const csv = generateCsv(headers, items);
        downloadCsvFile(
          csv,
          `${this.$t("menu.complaints")}_${this.$moment().format(
            "dateTime"
          )}.csv`
        );
      }
    },
    updateComplaint(id, model) {
      var cpl = this.complaints.find((c) => c.id === id);
      cpl.loading = true;
      ComplaintsService.UpdateComplaintCard(model)
        .then((c) => {
          EmitSuccess(this.$t("complaints.editSuccess", { id: c.cplCid }));
          cpl.description = c.description;
        })
        .finally(() => (cpl.loading = false));
    },
    exportAllToExcel() {
      this.isExportLoading = true;

      const data = {
        ...this.filtersDto,
        pageNumber: 1,
        pageSize: this.pagination.itemsLength,
      };

      ComplaintsService.GetComplaintsReport(data)
        .then((res) =>
          downloadFile(
            res,
            `${this.$t("menu.complaints")}_${this.$moment().format(
              "dateTime"
            )}.xlsx`,
            "application/vnd.ms-excel"
          )
        )
        .finally(() => {
          this.isExportLoading = false;
          this.isConfirmExportModalShown = false;
        });
    },
    cancelExportAllToExcel() {
      ComplaintsService.CancelGetComplaintsReport();
      this.isConfirmExportModalShown = false;
    },
    _formatDataToExport(data) {
      const items = [];
      data.forEach((d) => {
        const item = [];
        Object.keys(this.fieldsToExport).forEach((f) => {
          item.push(d[f]);
        });
        items.push(item);
      });
      return items;
    },
    showDetails(item) {
      this.$router.push({
        name: "complaintDetails",
        params: { id: item.id, selectedCustomer: this.selectedCustomer },
      });
    },
    mapFiltersToRequest() {
      const filters = { ...this.filtersDto };
      if (filters.owner) {
        filters.owner = filters.owner.id;
      }
      if (Array.isArray(filters.tags) && filters.tags.length > 0) {
        filters.tags = filters.tags.map((item) => item.id);
      }

      return filters;
    },
    getComplaints(pagination) {
      if (this.isLoadingComplaints || !this.selectedCustomer) return;

      this.isLoadingComplaints = true;
      this.complaints = [];
      return ComplaintsService.GetComplaints({
        ...this.mapFiltersToRequest(),
        ...this._getPagination(pagination),
      })
        .then((res) => {
          this.complaints = res.data.complaintListModel.map((complaint) => {
            complaint.color = getComplaintStateColor(complaint.cplStateId);
            complaint.loading = false;
            complaint.error = "";
            return complaint;
          });

          this.complaintStates = res.data.stateCount.map((state) => {
            return {
              ...state,
              color: getComplaintStateColor(state.id),
              icon: state.code,
            };
          });

          this.pagination = {
            ...this.pagination,
            page: res.pagination.currentPage,
            itemsPerPage: res.pagination.itemsPerPage,
            itemsLength: res.pagination.totalItems,
            pageStart:
              (res.pagination.currentPage - 1) * res.pagination.itemsPerPage,
            pageStop: res.pagination.currentPage * res.pagination.itemsPerPage,
            pageCount: Math.ceil(
              res.pagination.totalItems / res.pagination.itemsPerPage
            ),
          };
        })
        .finally(() => (this.isLoadingComplaints = false));
    },
    _getPagination(pagination = this.pagination) {
      return {
        pageNumber: pagination.page,
        pageSize: pagination.itemsPerPage,
      };
    },
    _setFilters(prioritiesPayload, categoriesHierarchyPayload) {
      const priorities = [
        {
          type: "treeSelect",
          name: this.$t("complaints.category"),
          value: "subCategories",
          options: categoriesHierarchyPayload,
        },
        {
          type: "multiSelect",
          name: this.$t("orderBy.PRIORITY"),
          value: "priorities",
          options: prioritiesPayload.map((g) => {
            return { name: g.text, value: g.id };
          }),
        },
        {
          type: "datePicker",
          name: this.$t("complaints.creationDate"),
          value: "creationDate",
          options: [
            {
              name: this.$t("common.from"),
              value: "from",
            },
            {
              name: this.$t("common.to"),
              value: "to",
              endOfDay: true,
            },
          ],
        },
        {
          type: "searcher",
          name: this.$t("complaints.owner"),
          value: "owner",
          options: {
            displayName: (item) => {
              return `${item.name} (${item.login})`;
            },
            searchFunc: (value) => {
              return UsersService.GetUsersSearch(value);
            },
          },
        },
        {
          type: "tags",
          name: this.$t("complaints.tags"),
          value: "tags",
          options: {},
        },
        {
          type: "bool",
          name: this.$t("complaints.jiraIntegration"),
          value: "isIntegrated",
          options: {},
        },
      ];
      this.filterOptions.push(...priorities);
    },
    formatFilters() {
      if (Object.entries(this.filters).length > 0) {
        return {
          priorities: [...this.filters.priorities],
          subCategories: [...this.filters.subCategories],
          realisationDateFrom: this.filters.realisationDate
            ? this.$moment.convertToQueryString(
                this.filters.realisationDate.from
              )
            : null,
          realisationDateTo: this.filters.realisationDate
            ? this.$moment.convertToQueryString(this.filters.realisationDate.to)
            : null,
          insertDateFrom: this.filters.creationDate
            ? this.$moment.convertToQueryString(this.filters.creationDate.from)
            : null,
          insertDateTo: this.filters.creationDate
            ? this.$moment.convertToQueryString(this.filters.creationDate.to)
            : null,
          caller: (this.selectedCustomer && this.selectedCustomer.id) || null,
          owner: this.filters.owner,
          tags: this.filters.tags,
          isIntegrated:
            typeof this.filters.isIntegrated === "boolean"
              ? this.filters.isIntegrated
              : null,
        };
      } else if (this.selectedCustomer) {
        return {
          caller: this.selectedCustomer.id,
        };
      }

      return null;
    },
    searchHandler(search) {
      this.pagination.page = 1;
      this.searchField = search;
    },
    selectAll() {
      if (
        this.complaints.every((x) =>
          this.selectedComplaints.some((y) => y.id == x.id)
        )
      ) {
        this.selectedComplaints = this.selectedComplaints.filter(
          (x) => !this.complaints.some((y) => x.id == y.id)
        );
      } else {
        this.selectedComplaints.push(
          ...this.complaints.filter(
            (x) => !this.selectedComplaints.some((y) => x.id == y.id)
          )
        );
      }
    },
    showHideDescription() {
      this.headerActionsMenuOpen = false;
      if (this.selectedComplaintsExpanded) {
        this.expandedComplaintIds = [];
      } else {
        this.expandedComplaintIds = this.selectedComplaints.map((x) => x.id);
      }
    },
    onExpandDetails(id) {
      if (this.expandedComplaintIds.includes(id)) {
        this.expandedComplaintIds = this.expandedComplaintIds.filter(
          (expandedId) => expandedId !== id
        );
      } else {
        this.expandedComplaintIds = [...this.expandedComplaintIds, id];
      }
    },
    onAddNewComplaint() {
      this.$router.push({
        name: "newComplaint",
        params: {
          setComplaintCaller: this.selectedCustomer,
          selectedCustomer: this.selectedCustomer,
        },
      });
    },
    setInitialCustomer(newCustomer) {
      if (newCustomer) {
        this.initialSelectedCustomer = `${newCustomer.firstName} ${newCustomer.surname}`;
        this.selectedCustomer = newCustomer;
      }
    },
    onClearInitialCustomer() {
      this.initialSelectedCustomer = null;
      this.selectedCustomer = null;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 16px;

  .customer-field-filters {
    margin-left: 10px;
  }
}

.group-list {
  .group-item-title {
    margin-left: 30px;
  }
}

.toolbar-buttons {
  margin-right: 10px;
  font-size: 12px;
}

.mobile-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .label {
    font-size: 12px;
    color: $label;
  }
}
</style>
